const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://genteydestino.dvgeo.app' : 'http://192.168.100.106:3020',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://genteydestino.dvgeo.app' : 'http://192.168.100.106:3020',
    api: 'api/',
    apiSocket: 'genteydestino::1.2',
    nameDir: 'genteydestino',
    package: 'app.dvgeo.genteydestino.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCx-2InXtwc4IPXb1jTO-SrzROAnGdIoOE',
    appName: 'Gente y Destino',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#A51E20',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.genteydestino.passenger',
    playStoreDriverId: 'app.dvgeo.genteydestino.driver',
    appStorePassengerId: '6479373234',
    appStoreDriverId: '64793733666',
    email: "rafael_gomez3007@hotmail.com",
};
export default config;
